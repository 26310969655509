import Color from 'color';
import { MAX_SHADES } from './constants';
import getBaseIndex from './getBaseIndex';
import precision from '../../../utils/precision';
import valueMinMax from '../../../utils/valueMinMax';
import convertToHexColor from '../../../utils/convertToHexColor';

// Returns the scaling value required to get the 000 shade to be as close
// as possible to desired lightness.
// Formula:
//    desiredLight = curLight • (1 + x) ^ distance
//    solve for x
function getLightnessScale(color, desiredLightFor000 = 0.97) {
  const distanceTo000 = getBaseIndex(color);
  const rawHSL = color.hsl();
  const curLight = precision(rawHSL.color[2], 1) / 100;
  // prettier-ignore
  const lightnessScale = ((desiredLightFor000 / curLight) ** (1 / distanceTo000)) - 1;
  return valueMinMax(precision(lightnessScale, 3), 0.05, 0.25);
}

// Returns the scaling value required to get the 900 shade to be as close
// as possible to desired saturation.
// Formula:
//    desiredSat = curSat • (1 - x) ^ distance
//    solve for x
function getSaturationScale(color, desiredSatFor900 = 0.45) {
  const distanceTo900 = MAX_SHADES - getBaseIndex(color) - 1;
  const rawHSL = color.hsl();
  const curSat = precision(rawHSL.color[1], 1) / 100;
  // prettier-ignore
  const absSaturationScale = 1 - ((desiredSatFor900 / curSat) ** (1 / distanceTo900));
  const saturationScale =
    curSat > desiredSatFor900 ? -absSaturationScale : absSaturationScale;
  return valueMinMax(precision(saturationScale, 3), -0.25, 0.25);
}

export default function getDefaultColorOptions(baseColorHex) {
  const color = Color(convertToHexColor(baseColorHex));
  return {
    baseColorHex,
    baseColorIndex: getBaseIndex(color),
    saturationScale: getSaturationScale(color),
    lightnessScale: getLightnessScale(color),
  };
}
