import React, { useCallback } from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { useSelector, useDispatch } from 'react-redux';
import ColorField from '../../../components/fields/ColorField';
import Slider from '../../../components/fields/SliderField';
import Button from '../../../components/buttons/Button';
import {
  getColorById,
  updateColor,
  autoBalanceColor,
} from '../../../reducers/colors';
import precision from '../../../utils/precision';

const Control = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ButtonControl = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SCALE = 1000;
const MAX_SCALED_VALUE = 0.3 * SCALE;
const WIDGET_WIDTH = 240;

function displayPercent(scaledValue) {
  const isPositive = scaledValue > 0;
  const percent = Math.abs(scaledValue) / 10;
  const fixedDigits = percent.toFixed(1);
  const paddedValue = percent > 10 ? `${fixedDigits}%` : `0${fixedDigits}%`;
  return isPositive ? paddedValue : `-${paddedValue}`;
}

function leftyPad(value) {
  return value === 0 ? '000' : `${value * 100}`;
}

const ColorCardControls = ({ colorId }) => {
  const color = useSelector((state) => getColorById(state, colorId));
  const dispatch = useDispatch();
  const {
    baseColorHex,
    baseColorIndex,
    saturationScale,
    lightnessScale,
  } = color.options;
  const scaledSaturation = Math.round(saturationScale * SCALE);
  const scaledLightness = Math.round(lightnessScale * SCALE);

  const onAutoBalance = useCallback(
    () => {
      dispatch(autoBalanceColor(colorId));
    },
    // eslint-disable-next-line
    []
  );

  const onBaseColorChange = useCallback(
    (event) => {
      // If the event is a string, then it's just the hex value.
      if (typeof event === 'string') {
        dispatch(updateColor(colorId, { baseColorHex: event }));
        return;
      }
      dispatch(updateColor(colorId, { baseColorHex: event.target.value }));
    },
    // eslint-disable-next-line
    []
  );

  const onIndexChange = useCallback(
    (value) => {
      dispatch(updateColor(colorId, { baseColorIndex: value }));
    },
    // eslint-disable-next-line
    []
  );

  const onSatScaleChange = useCallback(
    (value) => {
      dispatch(
        updateColor(colorId, {
          saturationScale: precision(value / SCALE, 3),
        })
      );
    },
    // eslint-disable-next-line
    []
  );

  const onLumScaleChange = useCallback(
    (value) => {
      dispatch(
        updateColor(colorId, {
          lightnessScale: precision(value / SCALE, 3),
        })
      );
    },
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Control>
        <div>
          <ColorField
            name="base_color"
            tooltip="All your colors in the gradient are derived from the base color. It is marked with • symbols."
            width={WIDGET_WIDTH}
            value={baseColorHex}
            onChange={onBaseColorChange}
          >
            Base Color
          </ColorField>
        </div>
      </Control>
      <Control>
        <Slider
          name="base_color_index"
          label="Base Color Position"
          tooltip="Determines the location of the base color in the gradient which impacts how the other colors are derived. For example, moving the color further to the left will darken the palette overall. "
          width={WIDGET_WIDTH}
          min={0}
          max={9}
          value={baseColorIndex}
          renderedValue={leftyPad(baseColorIndex)}
          onChange={onIndexChange}
        />
        <Slider
          name="saturation_scale"
          label="Saturation Rate"
          tooltip="Each derived color will have its saturation adjusted based on its distance from the base color. For example, a negative saturation scale will cause the shades to lose intensity by this percent for each step."
          width={WIDGET_WIDTH}
          min={-MAX_SCALED_VALUE}
          max={MAX_SCALED_VALUE}
          value={scaledSaturation}
          renderedValue={displayPercent(scaledSaturation)}
          onChange={onSatScaleChange}
        />

        <Slider
          name="lightness_scale"
          label="Lightness Rate"
          tooltip="Each derived color will have its lightness adjusted based on its distance from the base color. For example, a negative lightness scale will cause the shades to become darker by this percent for each step."
          width={WIDGET_WIDTH}
          min={0}
          max={MAX_SCALED_VALUE}
          value={scaledLightness}
          renderedValue={displayPercent(scaledLightness)}
          onChange={onLumScaleChange}
        />
      </Control>

      <ButtonControl>
        <ButtonContainer>
          <Tippy content="This will reset the adjustment options to the default values for the provided base color.">
            <Button size="md" variant="secondary" onClick={onAutoBalance}>
              Auto Balance Shades
            </Button>
          </Tippy>
        </ButtonContainer>
      </ButtonControl>
    </React.Fragment>
  );
};

export default ColorCardControls;
