import {
  GREY_000,
  GREY_100,
  GREY_200,
  GREY_500,
  GREY_800,
  RED_000,
  RED_500,
  RED_600,
} from '../../theme';

const BUTTON_SIZES = {
  block: {
    fontSize: '18px',
    padding: '12px 20px',
    width: '100%',
  },
  lg: {
    fontSize: '18px',
    padding: '10px 20px',
    width: 'auto',
  },
  md: {
    fontSize: '14px',
    padding: '8px 20px',
    width: 'auto',
  },
  sm: {
    fontSize: '12px',
    padding: '6px 10px',
    width: 'auto',
  },
};

export const BUTTON_VARIANTS = {
  primary: {
    color: GREY_800,
    colorActive: GREY_800,
    background: GREY_100,
    backgroundActive: GREY_200,
    border: 'transparent',
    borderActive: 'transparent',
  },
  secondary: {
    color: GREY_800,
    colorActive: GREY_800,
    background: 'transparent',
    backgroundActive: GREY_100,
    border: 'transparent',
    borderActive: 'transparent',
  },
  secondaryDark: {
    color: GREY_000,
    colorActive: GREY_800,
    background: 'transparent',
    backgroundActive: GREY_100,
    border: 'transparent',
    borderActive: 'transparent',
  },
  danger: {
    color: RED_000,
    colorActive: RED_000,
    background: RED_500,
    backgroundActive: RED_600,
    border: 'transparent',
    borderActive: 'transparent',
  },
  disabled: {
    color: GREY_500,
    background: GREY_000,
  },
};

export const getSize = (key) => ({ size }) => BUTTON_SIZES[size][key];
export const getVariant = (key) => ({ variant }) =>
  BUTTON_VARIANTS[variant][key];
