import { MAX_SHADES } from './constants';
import precision from '../../../utils/precision';
import valueMinMax from '../../../utils/valueMinMax';

export default function getBaseIndex(color) {
  const rawHSL = color.hsl();
  const lightness = precision(rawHSL.color[2], 1) / 100;
  const luminosity = color.luminosity();
  const relativeBrightness = (lightness + luminosity) / 2;
  return valueMinMax(
    MAX_SHADES - 1 - Math.ceil(relativeBrightness * MAX_SHADES),
    0,
    MAX_SHADES - 1
  );
}
