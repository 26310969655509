import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from './Hero';
import Export from './Export';
import Footer from './Footer';
import Section from './Section';
import NavigationBar from '../../components/NavigationBar';
import LayoutContainer from '../../components/layout/LayoutContainer';
import Spacer from '../../components/helpers/Spacer';
import ColorCard from './ColorCard';
import { GREY_800 } from '../../theme';

const AppPage = () => (
  <React.Fragment>
    <Helmet />
    <NavigationBar />
    <Spacer margin={85} />
    <Hero />
    <LayoutContainer>
      <Spacer margin={45} />

      <Section title="Step 1: Create Color Palettes">
        All colors used in your UI should serve a specific functionality. Do not
        use a color just because it looks nice in a particular situation.
        Consistency is key! There is a common language of what colors mean in
        any given culture. Try to leverage your user&apos;s existing
        expectations (e.g. green for success) whenever possible to ease
        onboarding. Finally, there should be sufficient contrast between the
        shades in your color palette to ensure that is{' '}
        <a href="https://a11yproject.com/posts/what-is-color-contrast/">
          accessible for users with poor vision
        </a>{' '}
        (and it&apos;ll help your SEO too).
        <Spacer margin={8} />
        The background color for your color palettes will be selected in Step 2.
      </Section>

      <ColorCard id="primary" name="Primary Color">
        Usually this color is the same as your brand color. The primary color,
        as the name suggests, is used for the primary action on any given page.
        For example, the call-to-action button would use this color. You want to
        train the user to look for this color.
      </ColorCard>
      <Spacer margin={45} />

      <ColorCard id="secondary" name="Secondary Color">
        Typically the accent color for your brand. The secondary color is less
        common since it does not fill any particular functionality niche. It can
        be very useful when creating illustrations or other marketing materials.
      </ColorCard>
      <Spacer margin={45} />

      <ColorCard id="success" name="Contextual Color: Success">
        Usually green. This accent color is to highlight positive trends or
        events. A success message upon logging in or number going up will use
        this color.
      </ColorCard>
      <Spacer margin={45} />

      <ColorCard id="danger" name="Contextual Color: Danger">
        Usually red (though in not all cultures). This accent color is used to
        warn the user of a negative event that is irreversible like deleting a
        resource.
      </ColorCard>
      <Spacer margin={45} />

      <ColorCard id="warning" name="Contextual Color: Warning">
        Usually yellow. This accent color is used to warn the user of a negative
        event that is either irreversible or can be resolved later. It is a
        lower-use accent color.
      </ColorCard>
      <Spacer margin={45} />

      <ColorCard id="info" name="Contextual Color: Info">
        Usually blue. This accent color is used to notify the user without
        making a judgement of whether if it is positive or negative. It is a
        lower-use accent color.
      </ColorCard>

      <Section title="Step 2: Pick a Neutral Color">
        The most common color used in UIs is grey. The background is some
        off-white, the text is an off-black and the borders are some shade that
        is in between. This is no accident! We want to use the more vibrant
        colors tactically as a way to communicate meaning. That requires there
        to be neutral color with no meaning to avoid overwhelming your users.
      </Section>

      <ColorCard id="greys" name="Neutral Color: Grey">
        The neutral color is almost always some sort shade of grey. You will
        need to decide if you want your grey to have a warmer or cooler tone.
        Your color section here will determine the background color for all of
        your color shades! <strong>000</strong> will be the background in light
        mode and <strong>900</strong> will be used in dark mode.
      </ColorCard>
      <Spacer margin={40} />
    </LayoutContainer>
    <Spacer id="export" margin={45} />

    <LayoutContainer bgColor={GREY_800}>
      <Section title="Step 3: Export Your Colors" isDark>
        Product colors will automatically save your color selections to your
        browser. When you are done creating your color palette you can export
        them to various formats here.
      </Section>
      <Export />
    </LayoutContainer>

    <LayoutContainer>
      <Spacer margin={85} />
      <Footer />
    </LayoutContainer>
  </React.Fragment>
);

export default AppPage;
