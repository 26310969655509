import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Button from '../buttons/Button';
import AnchorButton from '../buttons/AnchorButton';
import HSpacer from '../helpers/HSpacer';
import ResetDialog from './ResetDialog';
import { resetColors } from '../../reducers/colors';
import { GREY_000, GREY_800 } from '../../theme';

const Background = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${GREY_800};
  color: ${GREY_000};
  // Above codemirror
  z-index: 5;
  border-bottom: 1px solid ${GREY_000};
`;

const Bar = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  padding: 5px 0;
`;

const Logo = styled.div``;

const Buttons = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

const ResetButton = styled(Button)`
  font-weight: 400;
`;

const ExportButton = styled(AnchorButton)`
  font-weight: 400;
`;

const NavigationBar = () => {
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const handleColorReset = () => {
    dispatch(resetColors());
    setIsResetDialogOpen(false);
  };

  return (
    <React.Fragment>
      <ResetDialog
        isOpen={isResetDialogOpen}
        onAction={handleColorReset}
        onClose={() => setIsResetDialogOpen(false)}
      />
      <Background>
        <Bar>
          <Logo></Logo>
          <Buttons>
            <ResetButton
              size="lg"
              variant="secondaryDark"
              onClick={() => setIsResetDialogOpen(true)}
            >
              Reset Colors
            </ResetButton>
            <HSpacer margin={5} />
            <ExportButton size="lg" variant="secondaryDark" href="#export">
              Export Colors
            </ExportButton>
          </Buttons>
        </Bar>
      </Background>
    </React.Fragment>
  );
};

export default NavigationBar;
