import { SET_LANG } from '../reducers/options';
import {
  TOGGLE_COLOR,
  TOGGLE_DARK_MODE,
  UPDATE_COLOR,
  AUTO_BALANCE_COLOR,
  RESET_COLORS,
} from '../reducers/colors';

import { saveState } from './localStorage';

export default function localStorageMiddleware() {
  return (store) => (next) => (action) => {
    switch (action.type) {
      case SET_LANG:
      case TOGGLE_COLOR:
      case TOGGLE_DARK_MODE:
      case AUTO_BALANCE_COLOR:
      case UPDATE_COLOR:
      case RESET_COLORS: {
        const done = next(action);
        const state = store.getState();
        saveState(state);
        return done;
      }
      default:
        return next(action);
    }
  };
}
