import React from 'react';
import styled from 'styled-components';

const Background = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
`;

const Column = styled.div`
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
`;

export default function LayoutContainer({ children, bgColor }) {
  if (!bgColor) {
    return <Column>{children}</Column>;
  }
  return (
    <Background bgColor={bgColor}>
      <Column>{children}</Column>
    </Background>
  );
}
