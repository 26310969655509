import React from 'react';
import { animated } from 'react-spring';
import { GREY_900 } from '../../theme';

const ToggleIcon = ({
  id,
  width = 21,
  height = 14,
  animX = 0,
  animColor = GREY_900,
}) => {
  const maskId = `mask-${id}`;
  const maskCircleId = `${maskId}-circle`;
  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      focusable="false"
      data-prefix="fad"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 384"
    >
      <defs>
        <mask id={maskId}>
          <rect width="576" height="512" fill="#FFF" />
          <animated.svg x={animX}>
            <path
              id={maskCircleId}
              fill="#000"
              d="M192 320C166.684 320 141.937 312.493 120.887 298.428C99.8376 284.363 83.4315 264.372 73.7435 240.984C64.0555 217.595 61.5206 191.858 66.4595 167.028C71.3984 142.199 83.5893 119.392 101.49 101.49C119.392 83.5893 142.199 71.3984 167.028 66.4595C191.858 61.5206 217.595 64.0555 240.984 73.7435C264.372 83.4315 284.363 99.8376 298.428 120.887C312.493 141.937 320 166.684 320 192C320.009 208.812 316.705 225.461 310.275 240.994C303.846 256.528 294.418 270.643 282.53 282.53C270.643 294.418 256.528 303.846 240.994 310.275C225.461 316.705 208.812 320.009 192 320V320Z"
            />
          </animated.svg>
        </mask>
      </defs>
      <animated.path
        fill={animColor}
        d="M384 0H192C86 0 0 86 0 192C0 298 86 384 192 384H384C490 384 576 298 576 192C576 86 490 0 384 0Z"
        mask={`url(#${maskId})`}
      />
    </svg>
  );
};

export const MAX_ANIM_X = 192;

export default ToggleIcon;
