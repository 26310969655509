import React from 'react';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import Spacer from '../../helpers/Spacer';
import Label from './Label';
import Hint from './Hint';
import InfoIcon from '../../svg/InfoIcon';

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const LabelWithMargin = styled(Label)`
  margin-right: 4px;
`;

const FieldTrim = ({
  children,
  name,
  label,
  hint,
  tooltip,
  width = 'auto',
}) => (
  <Spacer margin={10} style={{ width }}>
    <LabelContainer>
      {label && <LabelWithMargin htmlFor={name}>{label}</LabelWithMargin>}
      {tooltip && (
        <Tippy content={tooltip}>
          <InfoIcon width={16} height={16} />
        </Tippy>
      )}
    </LabelContainer>

    {children}
    <Hint>{hint}</Hint>
  </Spacer>
);

export default FieldTrim;
