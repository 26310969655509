const BUTTON_SIZES = {
  lg: {
    fontSize: '18px',
    padding: '10px',
    width: 'auto',
  },
  md: {
    fontSize: '14px',
    padding: '8px',
    width: 'auto',
  },
  sm: {
    fontSize: '12px',
    padding: '6px',
    width: 'auto',
  },
};

export const getSize = (key) => ({ size }) => BUTTON_SIZES[size][key];
