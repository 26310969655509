import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getColorWarningsById } from '../../../reducers/colors';
import { GREY_100, GREY_800, BORDER_MD } from '../../../theme';
import ColorWarning from './ColorWarning';

const Background = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: ${BORDER_MD};
  background-color: ${GREY_800};
  color: ${GREY_100};
  border: 1px solid ${GREY_800};
  padding: 5px 15px 0 15px;
  margin: 15px 5px 5px 5px;
`;

const ColorCardFooter = ({ colorId }) => {
  const warnings = useSelector((state) => getColorWarningsById(state, colorId));
  if (!warnings.length) {
    return null;
  }

  return (
    <Background>
      {warnings.map(({ id, level, message, params }) => (
        <ColorWarning
          key={id}
          level={level}
          message={message}
          params={params}
        />
      ))}
    </Background>
  );
};

export default ColorCardFooter;
