import React from 'react';
import VisuallyHidden from '@reach/visually-hidden';
import styled from 'styled-components';
import CloseIcon from '../svg/CloseIcon';
import { GREY_300, GREY_000 } from '../../theme';

const ClickWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover path {
    fill: ${GREY_000};
  }
`;

const Icon = styled.div`
  padding: 2px 6px 0 0;
`;

const BackButton = ({ onClick }) => (
  <ClickWrapper onClick={onClick}>
    <VisuallyHidden>Close</VisuallyHidden>
    <Icon>
      <CloseIcon color={GREY_300} />
    </Icon>
  </ClickWrapper>
);

export default BackButton;
