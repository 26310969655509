import styled from 'styled-components';
import { BORDER_MD } from '../../theme';
import { getVariant, getSize, BUTTON_VARIANTS } from './utils';

const Button = styled.button`
  box-sizing: border-box;
  background: ${getVariant('background')};

  color: ${getVariant('color')};
  letter-spacing: 0.5px;
  font-size: ${getSize('fontSize')};
  font-weight: 700;

  border: 0px;
  border-radius: ${BORDER_MD};
  cursor: pointer;

  padding: ${getSize('padding')};
  width: ${getSize('width')};
  height: fit-content;
  margin: 0 0 2px 0;

  &:disabled {
    box-shadow: none;
    cursor: auto;
    background: ${BUTTON_VARIANTS.disabled.background};
    color: ${BUTTON_VARIANTS.disabled.color};
  }

  &:hover:enabled {
    background: ${getVariant('backgroundActive')};
    color: ${getVariant('colorActive')};
  }

  &:focus {
    outline-color: ${getVariant('colorActive')};
  }
`;

export default Button;
