import styled from 'styled-components';
import { GREY_800 } from '../../../theme';

export default styled.label`
  cursor: text;
  display: block;
  font-size: 14px;
  font-weight: 900;
  line-height: 14px;
  color: ${GREY_800};
  padding: 0;
`;
