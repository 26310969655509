import React, { forwardRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { GREY_900, GREY_500 } from '../../theme';
import ButtonContainer from './ButtonContainer';
import ToggleIcon, { MAX_ANIM_X } from './ToggleIcon';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const ICON_HEIGHTS = { lg: 20, md: 18, sm: 14 };
const TEXT_TOP_MARGIN = { lg: 2, md: 0, sm: 6 };

const ToggleButton = forwardRef(
  (
    {
      id,
      children,
      size,
      value,
      disabled,
      onClick,
      enabledColor = GREY_900,
      disabledColor = GREY_500,
    },
    ref
  ) => {
    const [progress, setProgress] = useState(value ? 1 : 0);
    const animProps = useSpring({
      x: progress * MAX_ANIM_X,
      color: value ? enabledColor : disabledColor,
    });
    useEffect(() => {
      setProgress(value ? 1 : 0);
    }, [value]);

    const onClickHandler = () => {
      onClick(!value);
    };
    const onHoverStart = () => {
      setProgress(value ? 0.33 : 0.66);
    };
    const onHoverEnd = () => {
      setProgress(value ? 1 : 0);
    };

    return (
      <ButtonContainer
        ref={ref}
        size={size}
        disabled={disabled}
        onMouseEnter={onHoverStart}
        onMouseLeave={onHoverEnd}
        onClick={onClickHandler}
      >
        <Container>
          <IconContainer>
            <ToggleIcon
              id={id}
              width={ICON_HEIGHTS[size] * 1.5}
              height={ICON_HEIGHTS[size]}
              animX={animProps.x}
              animColor={animProps.color}
            />
          </IconContainer>
          <animated.div
            style={{ color: animProps.color, marginTop: TEXT_TOP_MARGIN[size] }}
          >
            {children}
          </animated.div>
        </Container>
      </ButtonContainer>
    );
  }
);

export default ToggleButton;
