import React from 'react';
import styled from 'styled-components';
import { YELLOW_100, RED_200, GREY_800, BORDER_SM } from '../../../theme';
import WarningIcon from '../../../components/svg/WarningIcon';

const WarningContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
`;

const Text = styled.div`
  padding-left: 5px;
`;

const HighlightedText = styled.span.attrs(({ color, bgColor }) => ({
  style: {
    color,
    backgroundColor: bgColor,
  },
}))`
  box-sizing: border-box;
  padding: 0 6px 1px 6px;
  border-radius: ${BORDER_SM};
`;

const PriorityText = styled.span`
  color: ${({ color }) => color};
  font-weight: 600;
  padding-right: 3px;
`;

const HEADER_COLORS = {
  High: RED_200,
  Low: YELLOW_100,
};

const ColorWarning = ({ level, message, params }) => {
  const [leftText, highlightedText, rightText] = message.split('*');

  return (
    <WarningContainer>
      <WarningIcon
        width={16}
        height={16}
        color={GREY_800}
        bgColor={HEADER_COLORS[level]}
      />
      <Text>
        <PriorityText color={HEADER_COLORS[level]}>
          {level} Priority:{' '}
        </PriorityText>
        {leftText}
        {highlightedText && (
          <HighlightedText color={params.textColor} bgColor={params.bgColor}>
            {highlightedText}
          </HighlightedText>
        )}
        {rightText}
      </Text>
    </WarningContainer>
  );
};
export default ColorWarning;
