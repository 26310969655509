export const LG_SCREEN = 420;

export const WHITE_000 = '#FFFFFF';

export const GREY_000 = '#F6F8FA';
export const GREY_100 = '#C6D1DC';
export const GREY_200 = '#9EB1C2';
export const GREY_300 = '#7E96AC';
export const GREY_400 = '#647F98';
export const GREY_500 = '#556B7F';
export const GREY_600 = '#495A6A';
export const GREY_700 = '#3E4C59';
export const GREY_800 = '#333E48';
export const GREY_900 = '#2A323A';

export const RED_000 = '#FFF5F4';
export const RED_100 = '#FFC3BF';
export const RED_200 = '#FF978E';
export const RED_300 = '#FF7166';
export const RED_400 = '#FC584C';
export const RED_500 = '#EC4437';
export const RED_600 = '#DB3528';
export const RED_700 = '#BA362D';
export const RED_800 = '#9C3831';
export const RED_900 = '#843832';

export const YELLOW_000 = '#FFFDF5';
export const YELLOW_100 = '#FFED97';
export const YELLOW_200 = '#FFDF49';
export const YELLOW_300 = '#FFCC18';
export const YELLOW_400 = '#CDA30E';
export const YELLOW_500 = '#987B14';
export const YELLOW_600 = '#715D16';
export const YELLOW_700 = '#544616';
export const YELLOW_800 = '#3F3514';
export const YELLOW_900 = '#2F2912';

export const BORDER_SM = '2px';
export const BORDER_MD = '6px';
export const BORDER_LG = '8px';
