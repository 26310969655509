import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../components/buttons/Button';
import HSpacer from '../../../components/helpers/HSpacer';
import { getLang, setLang } from '../../../reducers/options';

const ExportControls = () => {
  const lang = useSelector(getLang);
  const dispatch = useDispatch();

  const handleModeCss = () => dispatch(setLang('css'));
  const handleModeScss = () => dispatch(setLang('scss'));
  const handleModeJs = () => dispatch(setLang('js'));
  const handleModeJson = () => dispatch(setLang('json'));

  return (
    <div>
      <Button
        size="md"
        variant="secondaryDark"
        disabled={lang === 'css'}
        onClick={handleModeCss}
      >
        CSS
      </Button>
      <HSpacer as="span" />
      <Button
        size="md"
        variant="secondaryDark"
        disabled={lang === 'scss'}
        onClick={handleModeScss}
      >
        SCSS
      </Button>
      <HSpacer as="span" />
      <Button
        size="md"
        variant="secondaryDark"
        disabled={lang === 'js'}
        onClick={handleModeJs}
      >
        JS
      </Button>
      <HSpacer as="span" />
      <Button
        size="md"
        variant="secondaryDark"
        disabled={lang === 'json'}
        onClick={handleModeJson}
      >
        JSON
      </Button>
    </div>
  );
};

export default ExportControls;
