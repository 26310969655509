import styled from 'styled-components';
import { WHITE_000, GREY_100, GREY_700, BORDER_LG } from '../../theme';

const Card = styled.div`
  box-sizing: border-box;
  box-shadow: 0 10px 6px -6px ${GREY_100}, 0 -10px 6px -10px ${GREY_100};
  border-radius: ${BORDER_LG};
  background: ${WHITE_000};
  border: 2px solid ${WHITE_000};
  padding: 2px;
`;
Card.Header = styled.div`
  box-sizing: border-box;
  padding: 15px;
`;
Card.Body = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
`;
Card.Footer = styled.div`
  box-sizing: border-box;
  padding: 10px 15px;
  color: ${WHITE_000};
  background: ${GREY_700};
  border-radius: 0 0 ${BORDER_LG} ${BORDER_LG};
`;

export default Card;
