import React, { useState, useEffect, useCallback } from 'react';
import Pickr from '@simonwep/pickr';
import isHexColor from '../../utils/isHexColor';

const ColorPicker = ({ value, onChange }) => {
  const [picker, setPicker] = useState(null);

  // Sync picker with prop changes.
  useEffect(
    () => {
      if (picker) {
        picker.setColor(isHexColor(value) && value.length > 4 ? value : null);
      }
    },
    // eslint-disable-next-line
    [value]
  );

  const pickrRef = useCallback(
    (node) => {
      // Will attempt to redraw when the component is destroyed which would
      // crash the app if `!node` was not there.
      if (!node || picker) {
        return;
      }

      const p = new Pickr({
        el: node,
        default: value,
        theme: 'nano',
        swatches: null,

        components: {
          preview: false,
          opacity: false,
          hue: true,

          interaction: {
            input: true,
          },
        },
      });

      p.on('change', (color) => {
        onChange(color.toHEXA().toString());
      });

      setPicker(p);
    },
    // eslint-disable-next-line
    []
  );

  return <div ref={pickrRef} />;
};

export default React.memo(ColorPicker);
