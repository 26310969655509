import styled from 'styled-components';
import { BORDER_MD, GREY_000, GREY_500, GREY_900 } from '../../theme';
import { getSize } from './utils';

const Button = styled.button`
  box-sizing: border-box;
  background: transparent;
  color: ${GREY_900};
  letter-spacing: 0.5px;
  font-size: ${getSize('fontSize')};
  font-weight: 700;

  border: 0px;
  border-radius: ${BORDER_MD};
  cursor: pointer;

  padding: ${getSize('padding')};
  width: ${getSize('width')};
  height: fit-content;
  margin: 0 0 2px 0;

  &:disabled {
    box-shadow: none;
    cursor: auto;
    background: ${GREY_500};
    color: ${GREY_000};
  }

  &:focus {
    outline-color: ${GREY_900};
  }
`;

export default Button;
