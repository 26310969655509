import Color from 'color';
import convertToHexColor from '../../../utils/convertToHexColor';
import precision from '../../../utils/precision';

export default function getA11yContrast(hexColorA, hexColorB) {
  const colorA = hexColorA.hex
    ? hexColorA
    : Color(convertToHexColor(hexColorA));
  const colorB = hexColorB.hex
    ? hexColorB
    : Color(convertToHexColor(hexColorB));
  const lumA = colorA.luminosity() + 0.05;
  const lumB = colorB.luminosity() + 0.05;
  const ratio = lumA > lumB ? lumA / lumB : lumB / lumA;
  return {
    ratio: precision(ratio, 2),
    isNormalAA: ratio > 4.5,
    isLargeAA: ratio > 3,
  };
}
