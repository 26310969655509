import React from 'react';
import styled from 'styled-components';
import { BORDER_MD } from '../../../theme';

const Chip = styled.div.attrs(({ bgColor }) => ({
  style: {
    backgroundColor: bgColor,
  },
}))`
  box-sizing: border-box;
  height: 85px;
  min-width: 130px;
  border-radius: ${BORDER_MD};
  padding: 1px;
  color: ${({ textColor }) => textColor};
  border: 1px dashed ${({ borderColor }) => borderColor};
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IdText = styled.div`
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 3px;
`;

const Hex = styled.div`
  font-size: 14px;
  text-align: center;
  margin-bottom: 2px;
`;

const HSL = styled.div`
  font-size: 11px;
  text-align: center;
`;

const Bumpers = styled.span`
  user-select: none;
  margin: 0;
  width: 17px;
`;

const ColorChip = ({ id, hex, hsl, textColor, isBase, isBackgroundColor }) => (
  <Chip
    bgColor={hex}
    textColor={textColor}
    borderColor={isBackgroundColor ? textColor : 'transparent'}
  >
    <IdText>
      {isBase ? <Bumpers>· </Bumpers> : null}
      {id}
      {isBase ? <Bumpers> ·</Bumpers> : null}
    </IdText>
    <Hex>{hex}</Hex>
    <HSL>{hsl}</HSL>
  </Chip>
);

export default React.memo(ColorChip);
