import React, { useCallback } from 'react';
import styled from 'styled-components';
import Color from 'color';
import Tippy from '@tippyjs/react';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from '../../../components/ToggleButton';
import ColorChip from './ColorChip';
import { getColorById, toggleDarkMode } from '../../../reducers/colors';
import { GREY_100, GREY_800, BORDER_MD } from '../../../theme';

const Background = styled.div.attrs(({ bgColor }) => ({
  style: {
    backgroundColor: bgColor,
  },
}))`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: ${BORDER_MD};
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 15px 10px;
  margin: 5px;
`;

const ColorChipsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ColorChips = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  min-width: 725px;
  max-width: 725px;
  margin-right: -15px;
`;

const Error = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  height: 180px;
  margin: 20px;
  background-color: ${GREY_100};
  color: ${GREY_800};
  border-radius: ${BORDER_MD};
`;

const ColorCardShades = ({ colorId }) => {
  const { shades: greyShades } = useSelector((state) =>
    getColorById(state, 'greys')
  );
  const { isDarkModeEnabled, shades } = useSelector((state) =>
    getColorById(state, colorId)
  );
  const dispatch = useDispatch();

  const grey000 = greyShades[0].hex;
  const grey900 = greyShades[9].hex;
  const bgColor = isDarkModeEnabled ? grey900 : grey000;
  const borderColor = isDarkModeEnabled ? 'transparent' : greyShades[2].hex;

  const onToggleDarkMode = useCallback(
    () => {
      dispatch(toggleDarkMode(colorId));
    },
    // eslint-disable-next-line
    []
  );

  if (!shades.length) {
    return <Error>Invalid Hex Color Code</Error>;
  }

  const shade000 = shades[0].hex;
  const shade900 = shades[9].hex;

  return (
    <Background bgColor={bgColor} borderColor={borderColor}>
      <Tippy content="This will toggle the background between the Neutral 000 and 900 shades.">
        <ToggleButton
          id={`${colorId}-dark`}
          size="md"
          enabledColor={grey000}
          disabledColor={grey900}
          value={isDarkModeEnabled}
          onClick={onToggleDarkMode}
        >
          View Dark Mode
        </ToggleButton>
      </Tippy>
      <ColorChipsContainer>
        <ColorChips>
          {shades.map(({ id, hex, hsl, isBase }) => {
            const textColor = Color(hex).isLight() ? shade900 : shade000;
            return (
              <ColorChip
                key={id}
                id={id}
                hex={hex}
                hsl={hsl}
                textColor={textColor}
                isBase={isBase}
                isBackgroundColor={bgColor === hex}
              />
            );
          })}
        </ColorChips>
      </ColorChipsContainer>
    </Background>
  );
};

export default ColorCardShades;
