import React from 'react';
import Dialog from '../Dialog';
import Spacer from '../helpers/Spacer';

const ResetDialog = ({ isOpen, onAction, onClose }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog
      title="Reset Colors"
      actionButtonStyle="danger"
      actionButtonText="Reset Colors"
      onAction={onAction}
      onClose={onClose}
    >
      <span>
        This will reset all your color palette customizations. Refreshing the
        page will not restore them.
      </span>
      <Spacer />
      <span>Are you sure you want to continue?</span>
    </Dialog>
  );
};

export default ResetDialog;
