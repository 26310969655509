import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ToggleButton from '../../../components/ToggleButton';
import Card from '../../../components/Card';
import Divider from '../../../components/Divider';
import Split from '../../../components/helpers/Split';
import Spacer from '../../../components/helpers/Spacer';
import { getColorById, toggleColor } from '../../../reducers/colors';
import { GREY_200, GREY_700 } from '../../../theme';

const Center = styled.div`
  display: flex;
  align-items: center;
`;

const ColorPip = styled.div`
  box-sizing: border-box;
  background-color: ${({ bgColor }) => bgColor};
  border: 1px solid ${GREY_200};
  border-radius: 30px;
  height: 20px;
  width: 20px;
  margin-right: 6px;
`;

const H3 = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin: 0;
`;

const Description = styled.div`
  box-sizing: border-box;
  padding-top: 5px;
  font-size: 16px;
  line-height: 1.4;
  color: ${GREY_700};
`;

const ColorCardHeader = ({ colorId, name, description, divider = true }) => {
  const { options, isEnabled } = useSelector((state) =>
    getColorById(state, colorId)
  );
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(toggleColor(colorId));
  };

  return (
    <Card.Header>
      <div>
        <Split>
          <Center>
            <ColorPip bgColor={options.baseColorHex} />
            <H3>{name}</H3>
          </Center>

          <ToggleButton
            id={colorId}
            size="lg"
            value={isEnabled}
            onClick={onClick}
          >
            Use Color
          </ToggleButton>
        </Split>
      </div>

      <Description>{description}</Description>
      {divider && <Spacer margin={20} />}
      {divider && <Divider margin={0} />}
    </Card.Header>
  );
};

export default ColorCardHeader;
