import React from 'react';
import { useSelector } from 'react-redux';
import Card from '../../../components/Card';
import ColorCardHeader from './ColorCardHeader';
import ColorCardControls from './ColorCardControls';
import ColorCardShades from './ColorCardShades';
import ColorCardFooter from './ColorCardFooter';
import { getColorById } from '../../../reducers/colors';

const ColorCard = ({ children, id, name }) => {
  const { isEnabled } = useSelector((state) => getColorById(state, id));
  if (!isEnabled) {
    return (
      <Card>
        <ColorCardHeader
          colorId={id}
          name={name}
          description={children}
          divider={false}
        />
      </Card>
    );
  }
  return (
    <Card>
      <ColorCardHeader colorId={id} name={name} description={children} />
      <Card.Body>
        <ColorCardControls colorId={id} />
      </Card.Body>
      <ColorCardShades colorId={id} />
      <ColorCardFooter colorId={id} />
    </Card>
  );
};

export default ColorCard;
