import React from 'react';
import {
  SliderInput,
  SliderTrack,
  SliderTrackHighlight,
  SliderHandle,
  SliderMarker,
} from '@reach/slider';
import styled from 'styled-components';
import FieldTrim from './partials/FieldTrim';
import { GREY_700 } from '../../theme';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
`;

const Value = styled.div`
  font-size: 16px;
  font-weight: 900px;
  margin-left: 20px;
  margin-top: 8px;
`;

const Input = styled(SliderInput)`
  padding: 0;
  width: ${({ width = 200 }) => width};
`;

const Track = styled(SliderTrack)`
  padding: 0;
`;

const Highlight = styled(SliderTrackHighlight)`
  background: ${GREY_700};
`;

const Marker = styled(SliderMarker)`
  > [data-reach-slider-marker] [data-state='at-value'] {
    background: ${GREY_700};
    color: white;
  }
`;

const Handle = styled(SliderHandle)`
  border: 2px solid ${GREY_700};
`;

const SliderField = ({
  name,
  label,
  hint,
  tooltip,
  width,
  min = 0,
  max = 100,
  step = 1,
  value,
  renderedValue,
  onChange,
}) => {
  const onActualChange = (newValue) => {
    if (value === newValue) {
      return;
    }
    onChange(newValue);
  };
  return (
    <FieldTrim
      name={name}
      label={label}
      hint={hint}
      tooltip={tooltip}
      width={width}
    >
      <Container>
        <Input
          min={min}
          max={max}
          step={step}
          style={{ width: width - 30, padding: '15px 0' }}
          value={value}
          onChange={onActualChange}
        >
          <Track>
            <Highlight />
            <Marker value={value} />
            <Handle />
          </Track>
        </Input>
        <Value>{renderedValue || value}</Value>
      </Container>
    </FieldTrim>
  );
};

export default React.memo(SliderField);
