import React from 'react';
import styled from 'styled-components';
import Divider from '../../../components/Divider';
import Spacer from '../../../components/helpers/Spacer';
import LayoutContainer from '../../../components/layout/LayoutContainer';
import { GREY_800 } from '../../../theme';

const SignOff = styled.div`
  font-weight: 700;
  color: ${GREY_800};
  margin: 0 0 5px 0;
`;

const Copyright = styled.div`
  color: ${GREY_800};
  margin: 0 0 20px 0;
`;

const Footer = () => (
  <LayoutContainer>
    <Divider margin={40} />
    <SignOff>From Toronto, Canada with social distance.</SignOff>
    <Copyright>© Copyright 2020 Sasha Sirotkin</Copyright>
    <Spacer margin={40} />
  </LayoutContainer>
);

export default Footer;
