export const SET_LANG = 'options/SET_LANG';

const initialState = {
  lang: 'css',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANG:
      return {
        lang: action.payload.lang,
      };
    default:
      return state;
  }
}

export function setLang(lang) {
  return { type: SET_LANG, payload: { lang } };
}

export const getLang = (state) => state.options.lang;
