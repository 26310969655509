import { createSelector } from 'reselect';
import generateColorShades from './utils/generateColorShades';
import getDefaultColorOptions from './utils/getDefaultColorOptions';
import isHexColor from '../../utils/isHexColor';
import reduceToObj from '../../utils/reduceToObj';
import { getA11yWarnings, getOverflowWarnings } from './colorWarnings';

export const TOGGLE_COLOR = 'colors/TOGGLE_COLOR';
export const TOGGLE_DARK_MODE = 'colors/TOGGLE_DARK_MODE';
export const UPDATE_COLOR = 'colors/UPDATE_COLOR';
export const AUTO_BALANCE_COLOR = 'colors/AUTO_BALANCE_COLOR';
export const RESET_COLORS = 'colors/RESET_COLORS';

const KEYS = [
  'greys',
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
];

function createColor(name, baseColorHex, isEnabled = true) {
  return {
    id: name.toLowerCase(),
    name,
    isEnabled,
    isDarkModeEnabled: false,
    options: getDefaultColorOptions(baseColorHex),
    fixedShades: {},
  };
}

const initialState = {
  colors: {
    primary: createColor('Primary', '#8051D3'),
    secondary: createColor('Secondary', '#395DFF', false),
    greys: createColor('Greys', '#3E4C59'),
    info: createColor('Info', '#586ACF', false),
    success: createColor('Success', '#5ABD5E'),
    warning: createColor('Warning', '#FFCC18', false),
    danger: createColor('Danger', '#FC584C'),
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_COLOR: {
      const { id } = action.payload;
      const color = state.colors[id];
      if (!color) {
        return state;
      }
      return {
        colors: {
          ...state.colors,
          [id]: {
            ...color,
            isEnabled: !state.colors[id].isEnabled,
          },
        },
      };
    }
    case TOGGLE_DARK_MODE: {
      const { id } = action.payload;
      const color = state.colors[id];
      if (!color) {
        return state;
      }
      return {
        colors: {
          ...state.colors,
          [id]: {
            ...color,
            isDarkModeEnabled: !state.colors[id].isDarkModeEnabled,
          },
        },
      };
    }
    case UPDATE_COLOR: {
      const { id, options } = action.payload;
      const newColor = options.baseColorHex;
      const color = state.colors[id];
      if (newColor !== color.options.baseColorHex && isHexColor(newColor)) {
        return {
          colors: {
            ...state.colors,
            [id]: {
              ...color,
              options: getDefaultColorOptions(newColor),
            },
          },
        };
      }
      return {
        colors: {
          ...state.colors,
          [id]: {
            ...color,
            options: {
              ...color.options,
              ...options,
            },
          },
        },
      };
    }
    case AUTO_BALANCE_COLOR: {
      const { id } = action.payload;
      const color = state.colors[id];
      return {
        colors: {
          ...state.colors,
          [id]: {
            ...color,
            options: getDefaultColorOptions(color.options.baseColorHex),
          },
        },
      };
    }
    case RESET_COLORS: {
      return initialState;
    }
    default:
      return state;
  }
}

export function toggleColor(id) {
  return { type: TOGGLE_COLOR, payload: { id } };
}

export function toggleDarkMode(id) {
  return { type: TOGGLE_DARK_MODE, payload: { id } };
}

export function updateColor(id, options) {
  return { type: UPDATE_COLOR, payload: { id, options } };
}

export function autoBalanceColor(id, options) {
  return { type: AUTO_BALANCE_COLOR, payload: { id, options } };
}

export function resetColors() {
  return { type: RESET_COLORS };
}

export const getColors = createSelector(
  (state) => state.colors.colors,
  (colors) =>
    reduceToObj(KEYS, (key) => {
      const color = colors[key];
      const {
        baseColorHex,
        baseColorIndex,
        lightnessScale,
        saturationScale,
      } = color.options;
      return {
        [key]: {
          ...color,
          shades: generateColorShades(
            baseColorHex,
            baseColorIndex,
            lightnessScale,
            saturationScale
          ),
        },
      };
    })
);

export const getColorById = (state, id) => {
  const colors = getColors(state);
  return colors[id];
};

export const getColorShadeArray = createSelector(getColors, (colors) => {
  const enabledKeys = KEYS.filter((key) => colors[key].isEnabled);
  return enabledKeys.map((key) => {
    const { shades } = colors[key];
    return shades.map(({ id, hex }) => ({
      name: key === 'greys' ? 'grey' : key,
      id,
      hex,
    }));
  });
});

export const getColorWarnings = createSelector(getColors, (colors) =>
  reduceToObj(KEYS, (key) => {
    const color = colors[key];
    if (!color.shades || !color.shades.length) {
      return { [key]: [] };
    }
    const c000 = color.shades[0].hex;
    const c100 = color.shades[1].hex;
    const c800 = color.shades[8].hex;
    const c900 = color.shades[9].hex;
    const overflowLevel = key === 'greys' ? 'Low' : 'High';
    return {
      [key]: []
        .concat(getOverflowWarnings(overflowLevel, '000', c000))
        .concat(getOverflowWarnings(overflowLevel, '900', c900))
        .concat(getA11yWarnings('High', '000', '900', c000, c900))
        .concat(getA11yWarnings('High', '100', '900', c100, c900))
        .concat(getA11yWarnings('Low', '100', '800', c100, c800)),
    };
  })
);

export const getColorWarningsById = (state, id) => {
  const warnings = getColorWarnings(state);
  return warnings[id];
};
