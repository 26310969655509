import React from 'react';
import styled from 'styled-components';
import LayoutContainer from '../../../components/layout/LayoutContainer';
import Spacer from '../../../components/helpers/Spacer';
import { GREY_800, GREY_700, GREY_000 } from '../../../theme';

const Background = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  padding: 40px 0;
`;

const H2 = styled.h2`
  font-size: 30px;
  margin: 0 0 5px;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 1.4;
  width: 700px;
`;

const Section = ({ title, children, isDark = false }) => (
  <Background
    bgColor={isDark ? GREY_800 : 'transparent'}
    textColor={isDark ? GREY_000 : GREY_700}
  >
    <LayoutContainer>
      <H2>{title}</H2>
      <Spacer margin={8} />
      <Text>{children}</Text>
    </LayoutContainer>
  </Background>
);

export default Section;
