import React from 'react';
import styled from 'styled-components';
import { Dialog as DialogComponent } from '@reach/dialog';
import Button from '../buttons/Button';
import HSpacer from '../helpers/HSpacer';
import DialogCard from './DialogCard';
import DialogLayout from './DialogLayout';
import CloseButton from './CloseButton';
import { GREY_000 } from '../../theme';

const DialogH2 = styled.h2`
  color: ${GREY_000};
  margin: 0;
`;

const Dialog = ({
  children,
  title,
  onAction,
  actionButtonStyle = 'primary',
  actionButtonText = 'Accept',
  cancelButtonText = 'Cancel',
  onClose,
}) => (
  <DialogComponent aria-label={title} onDismiss={onClose}>
    <DialogLayout>
      <DialogCard>
        <DialogCard.Header>
          <DialogH2>{title}</DialogH2>
          <CloseButton onClick={onClose} />
        </DialogCard.Header>
        <DialogCard.Body>{children}</DialogCard.Body>
        <DialogCard.Footer>
          <Button variant="secondary" size="md" onClick={onClose}>
            {cancelButtonText}
          </Button>
          {onAction && (
            <React.Fragment>
              <HSpacer />
              <Button variant={actionButtonStyle} size="md" onClick={onAction}>
                {actionButtonText}
              </Button>
            </React.Fragment>
          )}
        </DialogCard.Footer>
      </DialogCard>
    </DialogLayout>
  </DialogComponent>
);

export default Dialog;
