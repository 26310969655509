import React from 'react';

const Logo = ({ width = 64, height = 64 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3018 32.0574L31.8941 45.6497C31.9324 46.0865 31.952 46.5287 31.952 46.9755C31.952 55.2465 25.247 61.9515 16.976 61.9515C8.70497 61.9515 1.99999 55.2465 1.99999 46.9755C1.99999 38.7045 8.70497 31.9995 16.976 31.9995C17.4227 31.9995 17.8649 32.0191 18.3018 32.0574Z"
        fill="#FC584C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9521 46.9754C61.9521 55.2464 55.2471 61.9514 46.9761 61.9514C38.7051 61.9514 32.0001 55.2464 32.0001 46.9754C32.0001 46.5117 32.0212 46.0529 32.0624 45.6L45.6006 32.0617C46.0536 32.0205 46.5124 31.9994 46.9761 31.9994C55.2471 31.9994 61.9521 38.7044 61.9521 46.9754Z"
        fill="#5ABD5E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2585 31.8975C17.8358 31.9334 17.4081 31.9516 16.9761 31.9516C8.70506 31.9516 2.00007 25.2467 2.00007 16.9756C2.00007 8.70463 8.70506 1.99965 16.9761 1.99965C25.2471 1.99965 31.9521 8.70463 31.9521 16.9756C31.9521 17.4076 31.9338 17.8353 31.8979 18.258L18.2585 31.8975Z"
        fill="#9E9E9E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.9518 16.9759C61.9518 25.2469 55.2468 31.9519 46.9758 31.9519C46.5268 31.9519 46.0824 31.9321 45.6434 31.8934L32.0583 18.3084C32.0196 17.8694 31.9998 17.4249 31.9998 16.9759C31.9998 8.70488 38.7048 1.99989 46.9758 1.99989C55.2468 1.99989 61.9518 8.70488 61.9518 16.9759Z"
        fill="#8051D3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
