import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AppPage from './pages/AppPage';

const Router = () => {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Product Colors"
        defaultTitle="Product Colors"
        meta={[
          {
            name: 'description',
            content: 'Easiest way to generate a website color palette.',
          },
        ]}
      />
      <BrowserRouter>
        <Switch>
          <Route path="*">
            <AppPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
