import getA11yContrast from './utils/getA11yContrast';

export function getA11yWarnings(level, idText, idBg, textColor, bgColor) {
  const { ratio, isNormalAA, isLargeAA } = getA11yContrast(textColor, bgColor);
  if (!isLargeAA) {
    return [
      {
        id: `${idText}-${idBg}-aa-large`,
        level,
        message: `Using ${idText} with ${idBg} fails AA for *large text* due to low contrast (${ratio} < 3.0)`,
        params: {
          textColor,
          bgColor,
        },
      },
    ];
  }
  if (!isNormalAA) {
    return [
      {
        id: `${idText}-${idBg}-aa-normal`,
        level,
        message: `Using ${idText} with ${idBg} fails AA for *normal text* due to low contrast (${ratio} < 4.5)`,
        params: {
          textColor,
          bgColor,
        },
      },
    ];
  }

  return [];
}

export function getOverflowWarnings(level, id, color) {
  if (color === '#FFFFFF') {
    return [
      {
        id: `${id}-white-overflow`,
        level,
        message: `${id} is at maximum lightness. No colors in your palette should be *white*.`,
        params: {
          textColor: '#000000',
          bgColor: '#FFFFFF',
        },
      },
    ];
  }

  if (color === '#000000') {
    return [
      {
        id: `${id}-white-overflow`,
        level,
        message: `${id} is at maximum darkness. No colors in your palette should be *black*.`,
        params: {
          textColor: '#FFFFFF',
          bgColor: '#000000',
        },
      },
    ];
  }

  return [];
}
