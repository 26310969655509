import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import '@reach/slider/styles.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/theme/material.css';
import '@simonwep/pickr/dist/themes/nano.min.css';
import 'tippy.js/dist/tippy.css';
import Router from './Router';
import './index.css';
import initStore from './init/store';

const store = initStore();

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Router />
    </HelmetProvider>
  </Provider>,
  document.getElementById('root')
);
