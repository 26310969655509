import { createSelector } from 'reselect';
import { getColorShadeArray } from '../../../reducers/colors';
import { getLang } from '../../../reducers/options';
import reduceToObj from '../../../utils/reduceToObj';

function getJson(colors) {
  const jsonOutput = reduceToObj(colors, (colorSet) => ({
    ...reduceToObj(colorSet, ({ name, id, hex }) => ({
      [`${name.toUpperCase()}_${id}`]: hex,
    })),
  }));
  return JSON.stringify(jsonOutput, undefined, 2);
}

function makeColorExport(colors, func) {
  let jsString = '';
  colors.forEach((colorSet) => {
    colorSet.forEach(({ name, id, hex }) => {
      jsString += func(name, id, hex);
    });
    jsString += '\n';
  });

  return jsString.trim();
}

const getExportColors = createSelector(
  getColorShadeArray,
  getLang,
  (colors, lang) => {
    if (lang === 'css') {
      const cssColors = makeColorExport(
        colors,
        (name, id, hex) => `  --${name}-${id}: ${hex};\n`
      );
      return `:root {\n  ${cssColors}\n}`;
    }
    if (lang === 'scss') {
      return makeColorExport(
        colors,
        (name, id, hex) => `$${name}-${id}: ${hex};\n`
      );
    }
    if (lang === 'js') {
      return makeColorExport(
        colors,
        (name, id, hex) =>
          `export const ${name.toUpperCase()}_${id} = "${hex}";\n`
      );
    }
    if (lang === 'json') {
      return getJson(colors);
    }
    return '';
  }
);

export default getExportColors;
