import debounce from 'lodash.debounce';

const KEY = 'state';

export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export const saveState = debounce(
  (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(KEY, serializedState);
    } catch (err) {
      // Ignore write errors.
    }
  },
  250,
  { maxWait: 2000 }
);

export function resetState() {
  localStorage.removeItem(KEY);
}
