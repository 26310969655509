import Color from 'color';
import { MAX_SHADES } from './constants';
import isHexColor from '../../../utils/isHexColor';
import convertToHexColor from '../../../utils/convertToHexColor';
import precision from '../../../utils/precision';

function getColorInfo(color, value, isBase = false) {
  const id = value === 0 ? '000' : `${value * 100}`;
  const rawHSL = color.hsl();
  const hue = precision(rawHSL.color[0], 1);
  const saturation = precision(rawHSL.color[1], 1);
  const lightness = precision(rawHSL.color[2], 1);

  // We round the HSL value and generate the hex color off that.
  const hsl = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  const hex = color.hex();

  return {
    id,
    isBase,
    hex,
    hsl,
    hue,
    saturation,
    lightness,
  };
}

function mapToRange(count, iteratorFunc) {
  const result = [];
  let lastValue;
  for (let i = 0; i < count; i += 1) {
    lastValue = iteratorFunc(lastValue, i);
    result.push(lastValue);
  }
  return result;
}

export default function generateColorShades(
  baseColorHex,
  baseColorIndex,
  lightnessScale = 0.1,
  saturationScale = 0.1
) {
  if (!isHexColor(baseColorHex)) {
    return [];
  }
  const baseColor = Color(convertToHexColor(baseColorHex));
  const baseShade = getColorInfo(baseColor, baseColorIndex, true);

  const numLighterShades = baseColorIndex;
  const lighterShades = mapToRange(numLighterShades, (lastShade = baseColor) =>
    lastShade.lighten(lightnessScale).saturate(saturationScale)
  )
    .map((shade, i) => getColorInfo(shade, numLighterShades - 1 - i))
    .reverse();

  const numDarkerShades = MAX_SHADES - baseColorIndex - 1;
  const darkerShades = mapToRange(numDarkerShades, (lastShade = baseColor) =>
    lastShade.darken(lightnessScale).saturate(saturationScale)
  ).map((shade, i) => getColorInfo(shade, baseColorIndex + 1 + i));

  return lighterShades.concat(baseShade).concat(darkerShades);
}
