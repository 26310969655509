import React from 'react';
import styled from 'styled-components';
import FieldTrim from './partials/FieldTrim';
import ColorPicker from '../../components/ColorPicker';
import {
  WHITE_000,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_800,
  BORDER_MD,
} from '../../theme';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  padding: 4px 6px;
  margin: 0;
  background: ${WHITE_000};
  border: 1px solid ${GREY_300};
  border-radius: 0 ${BORDER_MD} ${BORDER_MD} 0;
  color: ${GREY_800};
  font-size: 16px;
  line-height: 14px;
  height: 34px;
  &:disabled {
    background: ${GREY_200};
  }
  ::placeholder {
    color: ${GREY_400};
    font-style: italic;
    opacity: 1; /* Firefox */
  }
`;

const TextField = ({ children, name, hint, tooltip, width, ...props }) => (
  <FieldTrim
    name={name}
    label={children}
    hint={hint}
    tooltip={tooltip}
    width={width}
  >
    <Container>
      <ColorPicker value={props.value} onChange={props.onChange} />
      <TextInput {...props} />
    </Container>
  </FieldTrim>
);

export default TextField;
