import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import Spacer from '../../../components/helpers/Spacer';
import ExportControls from './ExportControls';
import getExportColors from './getExportColors';
import { getLang } from '../../../reducers/options';
import { GREY_000, GREY_800 } from '../../../theme';

const Background = styled.div`
  background-color: ${GREY_800};
  color: ${GREY_000};
  padding: 0 0 45px;
`;

const Export = () => {
  const exportColors = useSelector(getExportColors);
  const lang = useSelector(getLang);
  const mode = lang === 'css' || lang === 'scss' ? 'css' : 'javascript';

  return (
    <Background>
      <ExportControls />

      <Spacer />
      <CodeMirror
        value={exportColors}
        options={{
          mode,
          theme: 'material',
          lineNumbers: true,
          tabSize: 2,
          readOnly: true,
        }}
        autoScroll={false}
      />
    </Background>
  );
};

export default Export;
