import React from 'react';
import styled from 'styled-components';
import Divider from '../../../components/Divider';
import LayoutContainer from '../../../components/layout/LayoutContainer';
import Logo from '../../../components/svg/Logo';
import { GREY_700, GREY_800 } from '../../../theme';

const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const H1 = styled.h1`
  font-size: 50px;
  font-weight: 900;
  color: ${GREY_800};
  margin: 0 0 0 10px;
`;

const ValueProp = styled.div`
  font-size: 25px;
  font-weight: 700;
  color: ${GREY_700};
  margin: 20px 0 0 0;
  width: 600px;
`;

const ValueExplainer = styled.div`
  font-size: 20px;
  color: ${GREY_700};
  margin: 8px 0 80px 0;
  width: 600px;
`;

const Hero = () => (
  <LayoutContainer>
    <Line>
      <Logo width={45} height={45} />
      <H1>Product Colors</H1>
    </Line>

    <ValueProp>
      The fastest way to create a practical color palette for your app or
      website.
    </ValueProp>
    <ValueExplainer>
      A modern app or website design needs more than just a single shade of grey
      to create an appealing UI. Product Colors gives you step-by-step guidance
      in how to create a functional and accessible color palette from just a few
      starting shades of color.
    </ValueExplainer>
    <Divider />
  </LayoutContainer>
);

export default Hero;
